import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useState } from 'react'

import { Category, Props as CategoryProps } from './Category'
import { Filter } from './Filter'

export interface Props {
  claim?: string
  languageCode: string
  list: CategoryProps[]
}

export const GalleryCategories = memo(function GalleryCategories({
  claim,
  languageCode,
  list,
}: Props) {
  if (!list) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')

  return (
    <Container>
      {claim ? <Claim>{claim}</Claim> : null}
      {list.length > 1 ? (
        <Filters row wrap>
          <Filter
            active={filteredCategory === 'all'}
            category="all"
            title={useVocabularyData('all', languageCode)}
            onClick={() => {
              setFilteredCategory('all')
            }}
          />
          {uniqBy(list, 'label').map((item, index) => {
            const category = item.label
              .replace(/[^a-zA-Z]/g, '')
              .toLocaleLowerCase()

            return category ? (
              <Filter
                active={filteredCategory === category}
                category={category}
                key={index}
                onClick={(e) => {
                  setFilteredCategory(e.target.getAttribute('data-category'))
                }}
                title={item.label}
              />
            ) : undefined
          })}
        </Filters>
      ) : null}
      <Categories row wrap>
        {uniqBy(list, 'label').map((item, index) => {
          const category = item.label
            .replace(/[^a-zA-Z]/g, '')
            .toLocaleLowerCase()

          return (
            <Category
              className={
                filteredCategory === category || filteredCategory === 'all'
                  ? 'visible'
                  : undefined
              }
              key={index}
              {...item}
            />
          )
        })}
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  margin: 10.875rem auto 6.25rem;
  padding: 0 5.833vw;
  position: relative;

  @media (max-width: 1023px) {
    margin: 8.125rem auto 5.625rem;
  }
`

const Claim = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const Filters = styled(FlexBox)`
  margin-top: 1.875rem;

  @media (max-width: 1023px) {
    margin-top: 0.875rem;
  }
`

const Categories = styled(FlexBox)`
  margin: 3.3125rem -1.5rem 0 0;

  @media (max-width: 1023px) {
    margin: 3rem -0.75rem 0 0;
  }
`
