import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
}

export const Arrow = memo(function Arrow({ direction = 'L', onClick }: Props) {
  return (
    <Container dial={5} onClick={onClick}>
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 5rem;
  height: 5rem;
  border: 0.25rem solid ${rgba(theme.colors.variants.neutralLight3, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  margin-left: 1.5rem;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    border-color: transparent;
  }
  &:first-of-type {
    margin-left: 0;
  }

  svg {
    width: auto;
    height: 1.875rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight3};
    stroke-width: 3;
  }

  @media (max-width: 1023px) {
    width: 3.75rem;
    height: 3.75rem;
    margin-left: 0.75rem;
    svg {
      height: 1.5rem;
    }
  }
`
