import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Props as ImageProps } from 'app/components/Common/Image'
import useLockedBody from 'app/utils/useLockedBody'
import React, { memo, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Gallery } from './Gallery'

export interface Props {
  className?: string
  images?: ImageProps[]
  label: string
  languageCode: string
  thumbnails?: ImageProps[]
}

export const Category = memo(function Category({
  className,
  images,
  languageCode,
  thumbnails,
}: Props) {
  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState(0)

  const handler = (i: number) => {
    setClickedSlide(i)
  }

  if (modalGalleryStatus) {
    document.addEventListener('keydown', function (e) {
      switch (e.keyCode) {
        case 27:
          setLocked(false)
          setModalGalleryStatus(false)
          break
      }
    })
  }

  return (
    <>
      {thumbnails
        ? thumbnails.map((item, index) => (
            <Thumb
              className={className}
              key={index}
              onClick={() => {
                if (!modalGalleryStatus) {
                  setLocked(true)
                  setModalGalleryStatus(true)
                } else {
                  setLocked(false)
                  setModalGalleryStatus(false)
                }

                handler(index)
              }}
            >
              <LazyLoadImage effect="blur" {...item} />
            </Thumb>
          ))
        : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
            }}
            variant="R"
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      ) : null}
    </>
  )
})

const Thumb = styled.div`
  display: none;
  width: calc(33.333% - 1.5rem);
  cursor: pointer;
  margin: 1.5rem 1.5rem 0 0;
  overflow: hidden;
  padding-bottom: 23.6%;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &.visible {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    width: calc(33.333% - 0.75rem);
    margin: 0.75rem 0.75rem 0 0;
  }

  @media (max-width: 767px) {
    width: calc(50% - 0.75rem);
    padding-bottom: 36%;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
