import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  onClick?: (e: any) => void
  title: string
}

export const Filter = memo(function Filter({
  active = false,
  category,
  onClick,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      dial={5}
      onClick={onClick}
      row
      space="between"
    >
      <Title>{title}</Title>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 12.5vw;
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  margin-right: 1rem;
  padding: 0 0.75rem 1.125rem;
  position: relative;
  text-transform: uppercase;
  &.active {
    &:after {
      opacity: 1;
    }
    > div {
      opacity: 1;
    }
  }
  &:hover {
    > div {
      &:first-of-type {
        opacity: 1;
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:after {
    content: '';
    width: 100%;
    height: 0.625rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: auto;
    margin: 1rem 0.375rem 0;
    padding: 0 0.375rem 0.75rem;
    &:after {
      height: 0.375rem;
    }
  }
`

const Title = styled.div`
  font-size: 1.875rem;
  line-height: 1.875rem;
  opacity: 0.2;
  transition: 0.3s ease-in-out;

  @media (max-width: 1023px) {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`
